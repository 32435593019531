import firebase from "firebase/app";
import "firebase/app";
import "firebase/storage";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyBE4KF4l8p9_i5iD4JHuoKnZ02CinzE9Kk",
    authDomain: "personal-project-permian.firebaseapp.com",
    databaseURL: "https://personal-project-permian.firebaseio.com",
    projectId: "personal-project-permian",
    storageBucket: "personal-project-permian.appspot.com",
    messagingSenderId: "936158028206",
    appId: "1:936158028206:web:b8ee917d3ea18a444aa83f",
    measurementId: "G-69EJMF6BZ0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
